import { FormikProps, FormikValues } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { LinkDTO } from '../../../api/link';
import { CircleButton } from '../../GetCameraImageButton/GetCameraImageButton';
import { KeyValueEditor } from './KeyValueEditor';
import { KeyValueItemType } from './keyValueItemType';

function newItem(): KeyValueItemType {
  return {
    id: uuidv4(),
    itemKey: '',
    value: ''
  };
}

interface KeyValueProps {
  meta: FormikProps<FormikValues>;
  name: string;
  links: LinkDTO[];
}

function toKeyValueItem(link: LinkDTO): KeyValueItemType {
  return {
    // This is only a internal ID which can be used as react key
    id: uuidv4(),
    itemKey: link.title,
    value: link.to
  };
}

export const LinkFormEditor: React.FC<KeyValueProps> = ({ meta, name, links }) => {
  const { t } = useTranslation('common');
  const [items, setItems] = useState(
    (() => {
      if (!links || links.length === 0) {
        return [newItem()];
      }
      return links.map(toKeyValueItem);
    })()
  );

  const handleUpdate = useCallback((updatedItem: KeyValueItemType) => {
    setItems(current =>
      current.map(currentItem => {
        if (currentItem.id === updatedItem.id) {
          return updatedItem;
        }
        return currentItem;
      })
    );
  }, []);

  const handleDelete = useCallback((id: string) => {
    setItems(current => {
      const rest = current.filter(currentItem => currentItem.id !== id);
      if (rest.length === 0) {
        return [newItem()];
      }

      return rest;
    });
  }, []);

  const handleAdd = useCallback(() => {
    setItems(current => [...current, newItem()]);
  }, []);

  useEffect(() => {
    meta.setFieldValue(name, items);
    meta.setTouched({
      ...meta.touched,
      [name]: true
    });
    // Only trigger on items change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <>
      <div style={{ paddingTop: 12 }}>
        <CircleButton description={t('addLinkDescription')} onClick={handleAdd} />
      </div>
      <KeyValueEditor items={items} onUpdate={handleUpdate} onDelete={handleDelete} />
    </>
  );
};
