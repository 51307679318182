/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { Type } from 'class-transformer';
import { IsInt, IsOptional, IsUUID, ValidateNested } from 'class-validator';
import 'reflect-metadata';
import { PagingCountDTO } from './generic';
import { MaterialDTO } from './material';
import { TagDTO } from './tag';
import { SimpleTemplateDTO } from './template';

export class GroupMaterialDTO {
  @IsUUID()
  groupMaterialId!: string;

  @ValidateNested()
  @IsOptional()
  @Type(() => MaterialDTO)
  material?: MaterialDTO;

  @ValidateNested()
  @IsOptional()
  @Type(() => SimpleTemplateDTO)
  template?: SimpleTemplateDTO;

  @ValidateNested({ each: true })
  @Type(() => TagDTO)
  tags!: TagDTO[];
}

export class GroupMaterialsDTO extends PagingCountDTO {
  @ValidateNested({ each: true })
  @Type(() => GroupMaterialDTO)
  groupMaterials!: GroupMaterialDTO[];
}

export class CreateGroupMaterialDTO {
  @IsUUID()
  setGroupId!: string;

  @IsUUID()
  @IsOptional()
  materialId?: string;

  @IsUUID()
  @IsOptional()
  templateId?: string;

  @IsInt()
  position!: number;
}

/**
 * @deprecated use v2 api
 */
export class UpdateSortGroupMaterialsDTO {
  @IsUUID(undefined, { each: true })
  groupMaterialIds!: string[];
}

/**
 * V2 works with only the source-id and the target category and index.
 */
export class UpdateSortGroupMaterialsV2DTO {
  @IsUUID()
  sourceGroupMaterialId!: string;

  @IsInt()
  targetIndex!: number;
}
