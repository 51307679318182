import React, { ChangeEventHandler, useCallback } from 'react';
import { KeyValueItemType } from './keyValueItemType';
import { InputField } from '../../Form';
import Button from '../../Form/Button';

interface KeyValueItemProps {
  item: KeyValueItemType;
  onUpdate: (item: KeyValueItemType) => void;
  onDelete: (itemId: string) => void;
}

const KeyValueItem: React.FC<KeyValueItemProps> = ({ item, onUpdate, onDelete }) => {
  const handleUpdateKey: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      onUpdate({ ...item, itemKey: event.target.value });
    },
    [item, onUpdate]
  );

  const handleUpdateValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      onUpdate({ ...item, value: event.target.value });
    },
    [item, onUpdate]
  );

  const handleRemove = useCallback(() => {
    onDelete(item.id);
  }, [item.id, onDelete]);

  return (
    <div
      style={{
        display: 'flex',
        gap: 12
      }}
    >
      <Button
        style={{
          alignSelf: 'center'
        }}
        className="btn_small margin-left"
        onClick={handleRemove}
      >
        <img src="images/bin.svg" alt="delete link" />
      </Button>
      <InputField name="name" defaultValue={item.itemKey} placeholder="Titel" onChange={handleUpdateKey} />
      <InputField name="link" defaultValue={item.value} placeholder="Link" onChange={handleUpdateValue} />
    </div>
  );
};

interface KeyValueProps {
  items: KeyValueItemType[];

  onUpdate: (item: KeyValueItemType) => void;
  onDelete: (itemId: string) => void;
}

export const KeyValueEditor: React.FC<KeyValueProps> = ({ items, onUpdate, onDelete }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      {items.map(item => (
        <KeyValueItem key={item.id} item={item} onUpdate={onUpdate} onDelete={onDelete} />
      ))}
    </div>
  );
};
