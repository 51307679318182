import React, { Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { getItemStyle } from 'util/dragAndDrop';
import { ProcedureDTO } from 'dto/procedure';
import { subjectArea, actions, AbilityContext } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';
import { useStores } from 'util/mobx/stores';
import { useAbility } from '@casl/react';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import ProcedureListItem from './ProcedureListItem';

interface Props extends OptionalLazyLoadProps {
  procedures: ProcedureDTO[];
  hover?: boolean;
  chapterId: string;
  onFlyoutOpen: (chapterId: string, position: number) => void;
  handleCloneProcedure: (chapterId: string, position: number) => void;
  handleCloneMaterialKnowledge: (chapterId: string, position: number) => void;
}

const ProcedureList = ({ procedures, onFlyoutOpen, handleCloneProcedure, handleCloneMaterialKnowledge, ...rest }: Props) => {
  const { guideStore } = useStores();
  const ability = useAbility(AbilityContext);

  return (
    <>
      {procedures &&
        procedures.map((procedure, index) => (
          <Fragment key={procedure.guideProcedureId}>
            {ability.can(
              actions.dragAndDrop,
              subject(subjectArea.guideProcedure, { departmentId: guideStore.selectedGuide?.departmentId })
            ) ? (
              <Draggable key={procedure.guideProcedureId} draggableId={procedure.guideProcedureId} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <ProcedureListItem
                      procedure={procedure}
                      onFlyoutOpen={procedureId => onFlyoutOpen(procedureId, index)}
                      handleCloneProcedure={procedureId => handleCloneProcedure(procedureId, index)}
                      handleCloneMaterialKnowledge={procedureId => handleCloneMaterialKnowledge(procedureId, index)}
                      {...rest}
                    />
                  </div>
                )}
              </Draggable>
            ) : (
              <ProcedureListItem
                procedure={procedure}
                onFlyoutOpen={procedureId => onFlyoutOpen(procedureId, index)}
                handleCloneProcedure={procedureId => handleCloneProcedure(procedureId, index)}
                handleCloneMaterialKnowledge={procedureId => handleCloneMaterialKnowledge(procedureId, index)}
                {...rest}
              />
            )}
          </Fragment>
        ))}
    </>
  );
};

export default ProcedureList;
