import React from 'react';
import { LinkDTO } from '../../../api/link';

export interface KeyedLinkDTO extends LinkDTO {
  // If an id is available - us it as key, otherwise the index is used.
  id?: string;
}

export interface LinksViewerProps {
  links: KeyedLinkDTO[];
}

export const LinksViewer: React.FC<LinksViewerProps> = ({ links }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 12
      }}
    >
      {links.map((link, i) => (
        <a key={link.id ?? i} target="_blank" rel="noopener noreferrer" href={link.to}>
          {link.title}c
        </a>
      ))}
    </div>
  );
};
