import ValueTag from 'components/ValueTag';
import { GroupMaterialDTO } from 'dto/groupMaterial';
import { InstrumentDTO } from 'dto/instrument';
import { MaterialDTO } from 'dto/material';
import { BasicMaterialSetDTO, MaterialSetDTO, SetCheckDTO, SetType } from 'dto/materialSet';
import { MaterialType } from 'dto/MaterialType';
import { PackageDTO, PackagingType, SimplePackageDTO } from 'dto/package';
import { MaterialStorageLocationDTO } from 'dto/storageLocation';
import { SimpleTemplateDTO } from 'dto/template';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getHoursTillNow } from 'util/date';
import { isERP } from 'util/materialUtils';

import './MaterialValueTags.css';

interface Props {
  fullInfo?: boolean;
  material?: MaterialDTO;
  template?: SimpleTemplateDTO;
  pack?: PackageDTO;
  simplePack?: SimplePackageDTO;
  instrument?: InstrumentDTO;
  materialSet?: BasicMaterialSetDTO;
  lastCheck?: SetCheckDTO | null;
  setGroupItemCount?: number;
  groupMaterial?: GroupMaterialDTO;
  materialStorageLocation?: MaterialStorageLocationDTO;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  classNameTitle?: string;
  classNameItem?: string;
}

const MaterialValueTags = ({
  fullInfo = false,
  material,
  template,
  pack,
  simplePack,
  instrument,
  materialSet,
  lastCheck,
  setGroupItemCount,
  groupMaterial,
  materialStorageLocation,
  onClick,
  classNameTitle = '',
  classNameItem = ''
}: Props) => {
  const { t } = useTranslation('materialTags');

  /**
   * buildTag creates a tag by title and value
   */
  function buildTag(title: string, value?: string, additionalItemClassName?: string, classNameValue?: string) {
    return (
      <ValueTag
        classNameTitle={classNameTitle}
        classNameItem={`${classNameItem} ${additionalItemClassName}`}
        classNameValue={classNameValue}
        title={title}
        tagValue={value}
        onClick={onClick}
      />
    );
  }

  /**
   * buildTagIf & buildTypeTagIf only creates a tag if the condition is true. If not, it just returns an empty element.
   */
  function buildTagIf(condition: boolean, title: string, value?: string, additionalItemClassName?: string, classNameValue?: string) {
    return condition ? buildTag(title, value, additionalItemClassName, classNameValue) : <></>;
  }
  function buildTypeTagIf(condition: boolean, value?: string) {
    return condition ? buildTag('', value, 'maximal-width', 'capital-value') : <></>;
  }

  function buildManufacturerArticleNumber(m: MaterialDTO) {
    return buildTag(t('manufacturerArticleNumber'), m.manufacturerArticleNumber);
  }

  function buildERPId(m: MaterialDTO) {
    return buildTag(t('ERP ID'), m.erpId);
  }

  function buildSetIdentNumber(p: PackageDTO | SimplePackageDTO) {
    return buildTag(t('setIdentNr'), p.serialNumber);
  }

  function buildSetMaterialLastCheck(s: MaterialSetDTO | BasicMaterialSetDTO, lc?: SetCheckDTO | null) {
    const lastCheckTime = s.lastCheck || lc?.createdAt;
    if (!lastCheckTime) {
      return <></>;
    }
    const hours = getHoursTillNow(lastCheckTime);
    return buildTag(t('lastCheck'), hours.humanize(true), 'capital-first-letter');
  }

  function buildGroupExpectedCount(itemCount: number) {
    return buildTagIf(itemCount !== undefined, t('expected'), t('expectedValue', { value: itemCount }));
  }

  function buildStorageLocationItemsCount(g: MaterialStorageLocationDTO) {
    return buildTagIf(g.amount !== undefined, t('expected'), t('expectedValue', { value: g.amount }));
  }

  if (material) {
    return (
      <>
        {buildManufacturerArticleNumber(material)}
        {fullInfo ? buildERPId(material) : <></>}
        {buildTypeTagIf(material.materialType === MaterialType.Material && isERP(material) && fullInfo, t('erpType'))}
        {buildTypeTagIf(material.materialType === MaterialType.Material && !isERP(material) && fullInfo, t('singleMaterialType'))}
        {buildTypeTagIf(material.materialType === MaterialType.Instrument && fullInfo, t('templateInstrumentType'))}
      </>
    );
  }

  if (template) {
    return <></>;
  }

  if (pack) {
    if (pack.packagingType === PackagingType.Single && pack.instruments[0]) {
      return (
        <>
          {buildSetIdentNumber(pack)}
          {buildManufacturerArticleNumber(pack.instruments[0].material)}
          {buildTypeTagIf(fullInfo, t('singleInstrumentType'))}
        </>
      );
    }
    // multi package
    return (
      <>
        {buildSetIdentNumber(pack)}
        {buildTypeTagIf(fullInfo, t('packageType'))}
      </>
    );
  }
  if (simplePack) {
    if (simplePack.packagingType === PackagingType.Single) {
      return (
        <>
          {buildSetIdentNumber(simplePack)}
          {buildTypeTagIf(fullInfo, t('singleInstrumentType'))}
        </>
      );
    }
    // multi package
    return (
      <>
        {buildSetIdentNumber(simplePack)}
        {buildTypeTagIf(fullInfo, t('packageType'))}
      </>
    );
  }

  if (instrument) {
    return (
      <>
        {buildManufacturerArticleNumber(instrument.material)}
        {buildTypeTagIf(fullInfo, t('templateInstrumentType'))}
      </>
    );
  }

  if (materialSet) {
    return (
      <>
        {buildSetMaterialLastCheck(materialSet, lastCheck)}
        {buildTypeTagIf(materialSet.type === SetType.Implants && fullInfo, t('materialSetImplantsType'))}
        {buildTypeTagIf(materialSet.type === SetType.Sets && fullInfo, t('materialSetsType'))}
        {buildTypeTagIf(materialSet.type === SetType.Medicals && fullInfo, t('materialSetMedicalsType'))}
        {buildTypeTagIf(materialSet.type === SetType.RentalMaterials && fullInfo, t('materialSetRentalMaterialsType'))}
      </>
    );
  }

  if (setGroupItemCount !== undefined) {
    return <>{fullInfo && buildGroupExpectedCount(setGroupItemCount)}</>;
  }

  if (groupMaterial?.material) {
    return <>{buildManufacturerArticleNumber(groupMaterial.material)}</>;
  }

  if (groupMaterial?.template) {
    return <></>;
  }

  if (materialStorageLocation) {
    return <>{buildStorageLocationItemsCount(materialStorageLocation)}</>;
  }

  return <></>;
};

export default MaterialValueTags;
