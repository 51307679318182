import { IsString } from 'class-validator';

export class LinkDTO {
  @IsString()
  title!: string;

  // TODO: IsURL doesn't work
  @IsString()
  to!: string;
}
